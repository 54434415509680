<template>

<div class="row justify-center">
  <div class="justify-center">
      <h4>IRL 2024</h4>
    </div>
</div>

<div class="row justify-center">
  <div class="col-xs-10 col-md-6">

    <div class="regular-text">
      Dates : 31/05/2024 au 05/06/2024
      Lieu : Château de Baillant, <a>1 lieu-dit Baillant, 86380</a>
    </div>

    <br>

    <div class="regular-text text-justify indent">
      Pour les 15 ans de Mandragore, nous nous sommes réunis au Château de Baillant pour profiter de 5 jours d’IRL. Il est sis dans un trou de verdure, malheureusement sans rivière, mais à l’abri des tumultes de la ville. C’était de nouveau l’occasion pour les Mandragores de s'adonner à la vie de château (et pour certains, à ses excès), comme pour l’IRL des 10 ans. 
    </div>

    <q-img src="/img/irl/2024/amis_faits_en_chemin.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Les amis faits en chemin
      </div>
    </q-img>

    <q-img src="/img/irl/2024/chateau_baillant_ecuries.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Le château de Baillant et ses écuries
      </div>
    </q-img>

    <div class="regular-text text-justify indent" style="margin-top: 20px;">
      A peine arrivés sur place, on se rend assez vite compte que le comité des fêtes n’a pas fait les choses à moitié ! Jacuzzi et piscine sont de la partie, une immense salle pour les activités, 2 cuisines pour mettre en œuvre les recettes trouvées sur marmiton, pas de dortoir de 6 avec les ronflements de Zbouair et Suprah. Bref, le paradis sur terre.<br><br>
      Après le verre de l’amitié en accueil, les hostilités peuvent commencer !
    </div>

    <q-img src="/img/irl/2024/salle_activite_repas.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        La salle pour les activités / repas
      </div>
    </q-img>

    <div class="regular-text text-justify" style="margin-top: 20px;">
      Présents : 
      Tsuky, Dedra, Hyzau, Tsao, Mélodie, Tetelle, Yersin, Giiiin, Michael, Zarg, Xubeo, Suprah, Zbouair, Parrou, Elise, Lauktar, Fire, Amara, Xool, Ward, Shamy, Kiru, Jimdun, Greg, Pedro, Late, Troliste, Bruneau, Aldia, Griffith, Mag, Kehyll, Bronto, Abrams, Rven, Cirdan, Azulis, Half, Brinsop, Alegra, Morsiat, Uther.
      <br><br>
      C’était la première IRL pour : 
      Xool, Fire, Aldia, Mag, Yersin, Mélodie, Xubeo, Kiru, Uther
    </div>

    <q-separator inset style="margin-top:40px;" />

    <div class="regular-text text-justify indent" style="margin-top: 20px;">
      <span style="font-size: 30px;">L</span>e patch de cette année à introduit une nouvelle monnaie, le Mandracoin. Elle s’obtient en s’inscrivant à l’IRL, en échangeant avec d’autres PJ (contres des services divers et variés, parce qu’on aime le capitalisme), en la trouvant un peu partout sur la map (cachée par les PNJ du comité des fêtes et leurs assistants), ou en participant aux activités. Grâce aux Mandracoins, toutes les réalités, toutes les dimensions vous sont ouvertes ! En particulier si vous voulez devenir l’heureux propriétaire de goodies Mandragore, tels que des verres à shot, des serviettes de plage exclusives, des goodies des autres années, ou encore des Lovecraft Letter artisanaux craftés par Abrams.
    </div>

    <q-img src="/img/irl/2024/mandracoins.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Des Mandracoins
      </div>
    </q-img>

    <div class="regular-text text-justify indent" style="margin-top: 20px;">
      Une activité fil rouge a été organisée par El famoso Johnny la classe :  La chasse au proto drake perdu dans le temps. Bien que Baillant ne soit pas dans les pics foudroyés, un timelost était en jeu ! Pour le gagner, les Mandra se sont organisés en équipe pour participer à différents jeux tels que :
      <ul class="custom-list"> 
        <li>des quizz de culture (très) générale,</li>
        <li>un concours de lancer d’avion en papier, </li>
        <li>un concours d’épluchage de pommes de terre (la meilleure façon d’obtenir de l’aide pour préparer une tartiflette), </li>
        <li>une course aux objets (rapporter des ceintures, du PQ, des cailloux, des coussins, etc… plus vite que ses adversaires).</li>
      </ul>

      C’est l’équipe de Mag qui en est sortie victorieuse, mais malheureusement pour eux, Johnny la classe a finalement gardé le timelost…
    </div>

    <q-img src="/img/irl/2024/techniques_epluchage_differentes.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Des techniques d'épluchage différentes (mais Parouu maintient que c’est efficace).
      </div>
    </q-img>

    <q-img src="/img/irl/2024/controle_qualite_azulis.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Un contrôle qualité très qualitatif assuré par Azulis
      </div>
    </q-img>

    <q-img src="/img/irl/2024/nouvelle_quete_apporter_coussins.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Nouvelle quête : rapporter des coussins
      </div>
    </q-img>

    <q-separator inset style="margin-top: 40px;"/>

    <div class="regular-text text-justify indent" style="margin-top: 30px;">
      Cette année encore, le jacuzzi et la piscine (malgré sa température) ont été largement exploités. Comme vous ne le savez peut-être pas, chaque année, Mandragore investit beaucoup de ressources dans la formation de ses membres à l’entretien des Jacuzzi. En particulier, une nouvelle méthode inédite de nettoyage de jacuzzi à été mise au point à l’IRL 2023 et a été reproduite en 2024. Une image valant mieux que mille mots, voici le fruit de ces années de travail acharné :
    </div>

    <q-img src="/img/irl/2024/brevet_depose.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Brevet déposé™
      </div>
    </q-img>

    <q-img src="/img/irl/2024/piscine.jpg" style="margin-top: 20px;">
    </q-img>

    <q-separator inset style="margin-top: 40px;"/>

    <div class="regular-text text-justify indent" style="margin-top: 30px;">
      Pour l’IRL 2023, Lauktar a oublié son short de bain au gîte de la Ravauderie. Quelques membres ont alors décidé de lui faire faire le tour du monde (au short, pas à Lauktar), parce que c’était marrant, quand même. Il a ainsi pu visiter, entre autres,les îles Canaries, l’Egypte, le Japon, ou encore la Thaïlande. Lauktar a pu le récupérer durant une cérémonie de retrouvailles très touchante durant laquelle son short lui a offert un florilège de photos de son périple.
    </div>

    <q-img src="/img/irl/2024/short_lauktar_voyage.jpg" style="margin-top: 20px;">
    </q-img>

    <q-img src="/img/irl/2024/regardez_joie_yeux.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Regardez la joie dans ses yeux (au short, pas à Lauktar)
      </div>
    </q-img>

    <q-separator inset style="margin-top: 40px;"/>

    <div class="regular-text text-justify indent" style="margin-top: 30px;">
      Autre activité prestigieuse de cette année : Le beer tasting. Cette année, Mandragore s’est associé avec les meilleurs brasseurs pour nous offrir des séances de dégustation de bière. Les participants ont été invités à découvrir de nouvelles saveurs, à reconnaître les arômes et épices utilisées, et partager un moment convivial autour de quelques cannettes. Sans surprise, c’est la 8.6 qui sort en tête du classement.
    </div>

    <q-img src="/img/irl/2024/cuvee_prestige.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Cuvée prestige
      </div>
    </q-img>

    <q-separator inset style="margin-top: 40px;"/>

    <div class="regular-text text-justify indent" style="margin-top: 30px;">
      En plus des habituels jeux de plateau que les membres ramènent maintenant par containers, nous avons pu faire l’expérience de Blood on the Clocktower (BotC pour les intimes, du sang sur la tour du clocher pour les non anglophones). Basés sur les mêmes principes que le Loup Garou mais en bien, ce jeu a été l’occasion de passer de super soirées à s'entre assassiner. Bon, parfois, c’est moins qu’une soirée, surtout quand Shamy ne comprend pas son rôle et fait wipe son équipe tour 1, après 30 minutes de préparation méticuleuse de la partie par Xool.
    </div>

    <q-img src="/img/irl/2024/blood_clock_tower.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Blood on the Clocktower
      </div>
    </q-img>

    <q-img src="/img/irl/2024/culte_lovecraft_letter.jpg" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Le maintenant culte Lovecraft Letter
      </div>
    </q-img>

    <q-separator inset style="margin-top: 40px;"/>

    <div class="regular-text text-justify indent" style="margin-top: 30px;">
      Un kouign-amann de guilde a été réalisé par 2 individus non identifiés en costume traditionnel breton. Il était salvateur le lendemain du beer-tasting !
    </div>

    <q-img src="/img/irl/2024/toute_ressemblance_fortuite.JPG" style="margin-top: 20px;">
      <div class="absolute-bottom regular-text text-center">
        Toute ressemblance avec des personnes existantes ou ayant existé est fortuite
      </div>
    </q-img>
  </div>
</div> 

</template>

<style scoped>

.indent {
  text-indent: 50px;
}

.custom-list {
  padding-left: 0rem; /* Adjust as needed */
  list-style-position: inside; /* Optional: moves the bullet inside */
}

.custom-list li {
  margin-left: 0; /* Remove extra margin */
  padding-left: 0rem; /* Adjust the space between the bullet and text */
}

</style>